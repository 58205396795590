<template>
    <leisure-profile-component/>
</template>

<script>
    import LeisureProfileComponent from "@/components/restaurants-leisures/LeisureProfileComponent";
    
    export default {
        name: "LeisuresProfile",
        title: "Perfil del Leisure | Turismo BC",
        components: { LeisureProfileComponent }
    }
</script>

<style scoped>

</style>